var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BPopover', {
    staticStyle: {
      "min-width": "500px"
    },
    attrs: {
      "target": _vm.target,
      "triggers": _vm.triggers,
      "placement": "topleft",
      "variant": "light",
      "boundary": "window",
      "custom-class": "wide-popover"
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "text-dark"
        }, [_vm._v(" " + _vm._s(_vm.$t("invoice.cancel.add".concat(_vm.upperFirst(_vm.type)))) + " ")])];
      },
      proxy: true
    }])
  }, [_c('ValidationObserver', {
    ref: "refFormObserver",
    staticClass: "text-dark"
  }, [_c('label', [_vm._v("Nhập danh sách email cách nhau bởi dấu chấm phẩy ("), _c('code', [_vm._v(";")]), _vm._v(")")]), _c('ValidationProvider', {
    attrs: {
      "rules": "required",
      "name": "Email"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors[0] || _vm.errorsValidate ? false : null,
            "name": "Email",
            "placeholder": "Nhập danh sách email, nhấn Enter để thêm"
          },
          on: {
            "input": _vm.onInputHandle,
            "keyup": function keyup($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
              return _vm.onSubmitHandle($event);
            }
          },
          model: {
            value: _vm.emails,
            callback: function callback($$v) {
              _vm.emails = $$v;
            },
            expression: "emails"
          }
        }), errors[0] ? _c('BFormInvalidFeedback', [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e(), _vm.errorsValidate ? _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" Email không hợp lệ: " + _vm._s(_vm.errorsValidate) + " ")]) : _vm._e()];
      }
    }])
  }), _c('div', {
    staticClass: "d-flex-center justify-content-end gap-1 mt-50"
  }, [_c('BButton', {
    attrs: {
      "variant": "secondary",
      "size": "sm"
    },
    on: {
      "click": _vm.onCancelPopoverHandle
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('cancel')) + " ")]), _c('BButton', {
    attrs: {
      "variant": "gradient",
      "size": "sm"
    },
    on: {
      "click": _vm.onSubmitHandle
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.cancel.add')) + " ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }