<template lang="">
  <BPopover
    :target="target"
    :triggers="triggers"
    placement="topleft"
    variant="light"
    style="min-width: 500px;"
    boundary="window"
    custom-class="wide-popover"
  >
    <template #title>
      <div class="text-dark">
        {{ $t(`invoice.cancel.add${upperFirst(type)}`) }}
      </div>
    </template>
    <ValidationObserver
      ref="refFormObserver"
      class="text-dark"
    >
      <label>Nhập danh sách email cách nhau bởi dấu chấm phẩy (<code>;</code>)</label>
      <ValidationProvider
        #default="{ errors }"
        rules="required"
        name="Email"
      >
        <b-form-input
          v-model="emails"
          :state="errors[0] || errorsValidate ? false : null"
          name="Email"
          placeholder="Nhập danh sách email, nhấn Enter để thêm"
          @input="onInputHandle"
          @keyup.enter="onSubmitHandle"
        />
        <BFormInvalidFeedback v-if="errors[0]">
          {{ errors[0] }}
        </BFormInvalidFeedback>

        <small
          v-if="errorsValidate"
          class="text-danger"
        >
          Email không hợp lệ: {{ errorsValidate }}
        </small>
      </ValidationProvider>
      <div class="d-flex-center justify-content-end gap-1 mt-50">
        <BButton
          variant="secondary"
          size="sm"
          @click="onCancelPopoverHandle"
        >
          {{ $t('cancel') }}
        </BButton>
        <BButton
          variant="gradient"
          size="sm"
          @click="onSubmitHandle"
        >
          {{ $t('invoice.cancel.add') }}
        </BButton>
      </div>
    </ValidationObserver>
  </BPopover>
</template>
<script>
import { ref } from '@vue/composition-api'
import {
  BButton, BFormInput, BFormInvalidFeedback, BPopover,
} from 'bootstrap-vue'
import upperFirst from 'lodash/upperFirst'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import { validatorEmail } from '@core/utils/validations/validators'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    BPopover, BFormInput, BButton, ValidationProvider, ValidationObserver, BFormInvalidFeedback,
  },
  props: {
    target: {
      type: String,
      required: true,
      default: '',
    },
    triggers: {
      type: String,
      default: 'click focus',
    },
    type: {
      type: String,
      default: 'cc',
    },
  },
  setup(props, { root, emit }) {
    const { refFormObserver } = formValidation()
    const emails = ref()
    const errorsValidate = ref()
    function onCancelPopoverHandle() {
      root.$emit('bv::hide::popover', props.target)
    }

    async function onSubmitHandle() {
      errorsValidate.value = ''
      const valid = await refFormObserver.value.validate()
      if (!valid) return
      const emailArray = emails.value.trim().split(';')
      let emailsReplace = ''
      emailArray.forEach(email => {
        if (validatorEmail(email)) {
          emit('push', email)
        } else {
          errorsValidate.value += `${email};`
          emailsReplace += `${email};`
        }
      })
      if (emailsReplace) emailsReplace = emailsReplace.slice(0, -1)
      if (errorsValidate.value) errorsValidate.value = errorsValidate.value.slice(0, -1)
      emails.value = emailsReplace
      if (!emailsReplace) onCancelPopoverHandle()
    }
    function onInputHandle() {
      if (errorsValidate.value) errorsValidate.value = ''
    }

    return {
      upperFirst,
      emails,
      onSubmitHandle,
      onCancelPopoverHandle,
      errorsValidate,
      refFormObserver,
      onInputHandle,
    }
  },
}
</script>
<style lang="scss" scoped>
.wide-popover {
  max-width: 500px;
}
</style>
